import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { firestore, db } from '../../lib/firebase';

//hooks
import useAuth from '../../hooks/useAuth';
import { useSelector } from '../../store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// images
import InstagramIcon from '../../../images/instagram.svg';
import YoutubeIcon from '../../../images/youtube.svg';
import BlogIcon from '../../../images/blogIcon.svg';
import * as Routes from '../../routes';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(({ breakpoints }) => ({
	footerContainer: {
		borderTop: '1px solid #E9EEF4',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		fontSize: '0.9em',
	},
	footerBottom: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		[breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			gap: 16,
		},
	},
	footerBox: {
		maxWidth: 1400,
		width: '100%',
		padding: '30px 100px',
		display: 'flex',
		justifyContent: 'space-between',
		[breakpoints.down('md')]: {
			width: '100%',
			padding: '30px',
		},
	},
	footerInfos: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		height: 'auto',
		width: '100%',
	},
	companyName: {
		marginBottom: 20,
		color: '#64748B',
		fontWeight: 'bold',
	},
	companyInfo: {
		color: '#64748B',
		lineHeight: 1.5,
	},
	termsBox: {
		marginTop: 20,
		color: '#64748B',
	},
	terms: {
		paddingRight: 30,
		cursor: 'pointer',
	},
	footerLinks: {},
	icon: {
		marginLeft: 15,
		cursor: 'pointer',
	},
	footer: {
		'& .companyName': {
			marginBottom: 20,
			color: '#64748B',
			fontWeight: 'bold',
		},
		'& .companyInfo': {
			color: '#64748B',
			lineHeight: 1.5,
		},
	},
}));

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
	const classes = useStyles();
	const { i18n } = useTranslation();
	const { id: clientId, tier } = useSelector(state => state.client);
	const { user, logout } = useAuth();
	const [footerInfo, setFooterInfo] = useState('');
	const history = useHistory();
	// 승인 요청
	function requestApprove() {
		if (user) {
			const userRef = db.collection('User').doc(user.id);
			userRef
				.get()
				.then(doc => {
					if (doc.exists) {
						const userData = doc.data();
						if (!userData?.cloudData?.[clientId]) {
							if (tier === 'business') {
								logout();
							} else {
								const isConfirmed = confirm(
									`전시를 생성하기 위해서는 관리자의 승인이 필요합니다. 승인을 요청하시겠습니까?`,
								);
								if (isConfirmed) {
									userRef
										.update({
											[`cloudData.${clientId}`]: {
												approved: 'wait',
												isBlocked: false,
												requestTime: firestore.Timestamp.now(),
											},
										})
										.then(() => {
											alert('신청되었습니다.');
										})
										.catch(error => {
											console.error('Error updating document: ', error);
										});
								}
							}
						} else {
							if (tier === 'business') {
								if (
									userData.cloudData[clientId].approved === 'approve' &&
									userData.cloudData[clientId].isAdmin
								) {
									history.push(`/${clientId}${Routes.cloudManageExhibition}`);
								} else {
									logout();
								}
							} else {
								history.push(`/${clientId}${Routes.cloudManageExhibition}`);
							}
						}
					}
				})
				.catch(error => {
					console.log('Error getting document:', error);
				});
		}
	}

	const toExhibitionBuildPage = () => {
		if (!user) {
			history.push(`/${clientId}${Routes.cloudLogin}`);
			return;
		} else {
			requestApprove();
		}
	};

	useEffect(() => {
		firestore()
			.collection('Client')
			.doc(clientId)
			.get()
			.then(result => {
				setFooterInfo(result.data().footerInfo);
			});
	}, []);

	return (
		<footer>
			<div className={classes.footerContainer}>
				<div className={classes.footerBox}>
					<div className={classes.footerInfos}>
						<div dangerouslySetInnerHTML={{ __html: footerInfo }} className={classes.footer}></div>

						<div className={classes.footerBottom}>
							<div className={classes.termsBox}>
								<span
									className={classes.terms}
									onClick={() => {
										window.open(
											'terms/use_condition',
											'target',
											'width=500, height=700, left=0, top=0, tollbar=no, menubar=no, location=no, status=no, scrollbars=no',
										);
										return false;
									}}
								>
									{i18n.t('이용약관')}
								</span>
								<span
									className={classes.terms}
									style={{ fontWeight: 'bold' }}
									onClick={() => {
										window.open(
											'terms/pipp',
											'target',
											'width=500, height = 700, left=0, top=0, tollbar=no, menubar=no, location=no, status=no, scrollbars=no',
										);
										return false;
									}}
								>
									{i18n.t('개인정보처리방침')}
								</span>
							</div>
							{tier === 'business' && (
								<div>
									<Button onClick={toExhibitionBuildPage} variant="outlined">
										관리자 페이지
									</Button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
