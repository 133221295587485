import * as Routes from "../routes";
import { useTranslation } from "react-i18next";
import {
  AccountBalanceRounded,
  ChatOutlined,
  Dashboard,
  HelpOutlineOutlined,
  InfoOutlined,
  LocationOnOutlined,
  OndemandVideoRounded,
  Panorama,
  PeopleAlt,
  PersonAdd,
  Publish,
} from "@material-ui/icons";
import { SIDE_MENU } from "../types/sidemenu";
import { useSelector } from "../store";
import useAuth from "./useAuth";

function useCloudMenu() {
  const { i18n } = useTranslation();
  const { id: clientId, tier } = useSelector(state => state.client);
  const { isCloudAdmin } = useAuth();
  const getMenus = () => {
    if (isCloudAdmin && tier === "enterprise") {
      return [
        ...SIDE_ADMIN_USER_MENU_ITEMS,
        ...SIDE_ADMIN_MENU_ITEMS,
        ...SIDE_MENU_ITEMS,
        ...SIDE_DEFAULT_MENU_ITEMS,
      ];
    } else if (isCloudAdmin && tier === "business") {
      return [...SIDE_MENU_ITEMS, ...SIDE_ADMIN_MENU_ITEMS, ...SIDE_DEFAULT_MENU_ITEMS];
    } else {
      return [...SIDE_MENU_ITEMS, ...SIDE_DEFAULT_MENU_ITEMS];
    }
  };
  const SIDE_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      title: i18n.t("내 전시 관리"),
      icon: AccountBalanceRounded,
      type: "route",
      value: `/${clientId}${Routes.cloudManageExhibition}`,
    },
    {
      title: i18n.t("내 전시 댓글 관리"),
      icon: ChatOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudManageComment}`,
    },
  ];
  const SIDE_ADMIN_USER_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      title: i18n.t("관리자"),
      type: "divider",
    },
    {
      title: i18n.t("가입 승인"),
      icon: PersonAdd,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminGrantUser}`,
    },
    {
      title: i18n.t("사용자 관리"),
      icon: PeopleAlt,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminManageUser}`,
    },
  ];
  const SIDE_ADMIN_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      type: "divider",
    },
    {
      title: i18n.t("출판 승인"),
      icon: Publish,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminGrantExhibition}`,
    },
    {
      title: i18n.t("전시 관리"),
      icon: Dashboard,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminManageExhibition}`,
    },
    {
      type: "divider",
    },
    {
      title: i18n.t("홈 배너 관리"),
      icon: Panorama,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminManageBanner}`,
    },
    {
      title: i18n.t("홈 섹션 관리"),
      icon: ChatOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudAdminSpecialExhibition}`,
    },
    // {
    //   title: i18n.t("사용자"),
    //   type: "divider",
    // },
    // {
    //   title: i18n.t("이벤트 관리"),
    //   icon: ChatOutlined,
    //   type: "route",
    //   value: `/${clientId}${Routes.cloudAdminEvents}`,
    // },
    // {
    //   title: i18n.t("사용자"),
    //   type: "divider",
    // },
  ];
  const SIDE_DEFAULT_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      type: "divider",
    },
    {
      title: i18n.t("가상전시공간"),
      icon: LocationOnOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudSpace}`,
    },
    {
      title: i18n.t("영상강좌"),
      icon: OndemandVideoRounded,
      type: "route",
      value: `/${clientId}${Routes.cloudTutorials}`,
    },
    {
      title: i18n.t("자주묻는질문"),
      icon: InfoOutlined,
      type: "link",
      value: "http://www.onthewall.io/FAQ",
    },
    {
      title: i18n.t("문의하기"),
      icon: HelpOutlineOutlined,
      type: "route",
      value: `/${clientId}${Routes.cloudAsk}`,
    },
  ];
  return getMenus();
}

export default useCloudMenu;
