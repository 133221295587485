import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Provider } from 'react-redux';
import '../public/static/fonts/font.css';
import './main.css';
import { AppState, items, slots } from './AppState';
import store from './store';
import { SettingsProvider } from './contexts/SettingsContext';
import App from './App';
import { enableES5 } from 'immer';
// import * as serviceWorker from './serviceWorker';

const appState = new AppState();
appState.items = items;
appState.slots = slots;

enableES5();

const container = document.getElementById('content') as HTMLElement;

if (container.hasChildNodes()) {
  ReactDom.hydrate(
    <Provider store={store}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </Provider>,
    container,
  );
} else {
  ReactDom.render(
    <Provider store={store}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </Provider>,
    container,
  );
}

// ReactDom.render(
//   <Provider store={store}>
//     <SettingsProvider>
//       <App />
//     </SettingsProvider>
//   </Provider>,
//   document.getElementById('content'),
// );

// serviceWorker.register();
