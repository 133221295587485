import React from 'react';
// import { i18n } from "../../plugins/i18next";
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Menu from './Menu';
import UserMenu from './UserMenu';

const useStyles = makeStyles(({ breakpoints }) => ({
	root: ({ isExpanded }: { isExpanded: boolean }) => ({
		width: isExpanded ? 290 : 60,
		borderRight: '1px solid #E2E2E2',
		transition: 'all 0.2s ease-out',
		position: 'fixed',
		left: 0,
		display: 'flex',

		overflowX: 'hidden',
		overflowY: 'auto',
		top: '56px',
		height: 'calc(100vh - 56px)',
		// scrollbar style
		'&::-webkit-scrollbar': {
			width: '4px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#ccc',
			borderRadius: '4px',
		},
		'&::-webkit-scrollbar-track': {
			backgroundColor: '#f1f1f1',
		},
	}),
	placeholder: ({ isExpanded }: { isExpanded: boolean }) => ({
		width: isExpanded ? 290 : 60,
		height: 'calc(100vh - 56px)',
	}),
	drawer: ({ isExpanded }: { isExpanded: boolean }) => ({
		width: 'calc(100% - 2px)',
		height: '100%',
		padding: isExpanded ? '12px 40px' : 12,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	}),
	userInfo: {},
	userName: {
		color: '#6D6D6D',
	},
	userEmail: {
		color: '#999999',
	},
	expandButton: ({ isExpanded }: { isExpanded: boolean }) => ({
		width: 30,
		height: 30,
		borderRadius: '50%',
		backgroundColor: '#F4F4F4',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '1px solid #E2E2E2',
		position: 'fixed',
		top: 75,
		left: isExpanded ? 275 : 45,
		transition: 'all 0.2s ease-out',
		cursor: 'pointer',
	}),
	rightHoverLine: ({ isExpanded }: { isExpanded: boolean }) => ({
		width: 2,
		height: 'calc(100vh - 56px)',
		opacity: isExpanded ? 0.3 : 0.7,
		transition: 'all 0.5s ease-out',
		'&:hover': {
			opacity: 1,
		},
	}),

	menuPosition: {
		// marginTop: 78,
	},
	topItem: {
		height: 'auto',
	},
	bottomItem: {
		height: 60,
		marginTop: 30,
	},
}));
type Props = {
	isExpanded: boolean;
	setExpand: React.Dispatch<React.SetStateAction<boolean>>;
};

function SideNavigation({ isExpanded, setExpand }: Props) {
	const history = useHistory();
	const { i18n } = useTranslation();

	const { user } = useAuth();

	const classes = useStyles({ isExpanded });

	const toggleExpand = () => {
		setExpand(prev => {
			// 설정값 저장
			sessionStorage.setItem('MAIN_DRAWER', JSON.stringify(!prev));
			return !prev;
		});
	};
	return (
		<>
			<div className={classes.root}>
				<nav className={classes.drawer}>
					<div className={classes.topItem}>
						<div className={classes.menuPosition}>
							<Menu isExpanded={isExpanded} />
						</div>
					</div>
					<div className={classes.bottomItem}>
						<UserMenu isExpanded={isExpanded} />
					</div>
				</nav>

				<div className={classes.rightHoverLine}>
					<Box className={classes.expandButton} onClick={toggleExpand} boxShadow={2}>
						{isExpanded ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
					</Box>
				</div>
			</div>
			<div className={classes.placeholder}></div>
		</>
	);
}

export default SideNavigation;
