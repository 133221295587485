import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import { firestore } from '../../lib/firebase';

//hooks
import useAuth from '../../hooks/useAuth';
import { useSelector } from '../../store';
import { useTranslation } from 'react-i18next';
// images
import InstagramIcon from '../../../images/instagram.svg';
import YoutubeIcon from '../../../images/youtube.svg';
import BlogIcon from '../../../images/blogIcon.svg';

const useStyles = makeStyles(({ breakpoints }) => ({
	footerContainer: {
		borderTop: '1px solid #E9EEF4',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		fontSize: '0.9em',
		backgroundColor: 'white',
	},
	footerBox: {
		maxWidth: 1400,
		width: '100%',
		padding: '30px 100px',
		display: 'flex',
		justifyContent: 'space-between',
		[breakpoints.down('sm')]: {
			width: '100%',
			padding: '30px',
		},
	},
	footerInfos: {},
	companyName: {
		marginBottom: 20,
		color: '#64748B',
		fontWeight: 'bold',
	},
	companyInfo: {
		color: '#64748B',
		lineHeight: 1.5,
	},
	termsBox: {
		marginTop: 20,
		color: '#64748B',
	},
	terms: {
		paddingRight: 30,
		cursor: 'pointer',
	},
	footerLinks: {},
	icon: {
		marginLeft: 15,
		cursor: 'pointer',
	},
	footer: {
		'& .companyName': {
			marginBottom: 20,
			color: '#64748B',
			fontWeight: 'bold',
		},
		'& .companyInfo': {
			color: '#64748B',
			lineHeight: 1.5,
		},
	},
}));

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
	const classes = useStyles();
	const { id: clientId, footerInfo } = useSelector(state => state.client);
	const { i18n } = useTranslation();
	// const [footerInfo, setFooterInfo] = useState("")

	// useEffect(() => {
	//   firestore()
	//     .collection("Client")
	//     .doc(clientId)
	//     .get()
	//     .then((result) => {
	//       console.log(result.data());

	//       setFooterInfo(result.data().footerInfo);
	//     });
	// }, []);

	return (
		<footer>
			<div className={classes.footerContainer}>
				<div className={classes.footerBox}>
					<div className={classes.footerInfos}>
						<div dangerouslySetInnerHTML={{ __html: footerInfo }} className={classes.footer}></div>

						<div className={classes.termsBox}>
							<span
								className={classes.terms}
								onClick={() => {
									window.open(
										'terms/use_condition',
										'target',
										'width=500, height=700, left=0, top=0, tollbar=no, menubar=no, location=no, status=no, scrollbars=no',
									);
									return false;
								}}
							>
								{i18n.t('이용약관')}
							</span>
							<span
								className={classes.terms}
								style={{ fontWeight: 'bold' }}
								onClick={() => {
									window.open(
										'terms/pipp',
										'target',
										'width=500, height = 700, left=0, top=0, tollbar=no, menubar=no, location=no, status=no, scrollbars=no',
									);
									return false;
								}}
							>
								{i18n.t('개인정보처리방침')}
							</span>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
