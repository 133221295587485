import React, { useEffect } from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import GoogleAnalytics from './components/GoogleAnalytics';
import { AuthProvider } from './contexts/FirebaseAuthContext';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';
import routes, { renderRoutes } from './routes';
import { initializeI18next, i18n } from './plugins/i18next';
import { I18nextProvider } from 'react-i18next';
import { firestore } from './lib/firebase';
import 'react-loading-skeleton/dist/skeleton.css';
import moment from 'moment';
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
	const { settings } = useSettings();
	useEffect(() => {
		initializeI18next();
	}, []);
	const theme = createTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme,
	});

	// useEffect(() => {
	//   firestore()
	//     .collection("Exhibition")
	//     .get()
	//     .then(snapshot => {
	//       let totalview = 0;
	//       snapshot.docs.forEach(doc => {
	//         totalview += doc.data().views.totalView ?? 0;
	//       });
	//       console.log("total view", totalview);
	//       console.log("전시회 수", snapshot.docs.length);
	//     });
	//   firestore()
	//     .collection("User")
	//     .get()
	//     .then(snapshot => {
	//       console.log("사용자 수", snapshot.docs.length);
	//     });
	// }, []);
	// useEffect(() => {
	// 	console.log('exhibition loaded');

	// 	let csv = '아이디, 날짜, 전시회명, 방문자수, 링크\n';
	// 	const promises = [];

	// 	let csv2 = '아이디, 전시회명, 방문자수\n';

	// 	firestore()
	// 		.collection('Exhibition')
	// 		.where('cloudData.clientId', '==', 'gimhaecitymuseum')
	// 		.get()
	// 		.then(snapshot => {
	// 			snapshot.docs.forEach(doc => {
	// 				if (doc.data().views?.totalView >= 0) {
	// 					const title = doc.data().title.replace(/,/g, '');
	// 					csv2 += `${doc.id}, ${title}, ${doc.data().views.totalView}\n`;
	// 				}
	// 				const p = firestore()
	// 					.collection('Logs')
	// 					.doc(doc.id)
	// 					.collection('ViewLogs')
	// 					.orderBy('createdAt') // 날짜 순으로 정렬
	// 					.get()
	// 					.then(view => {
	// 						const aggregatedData = {};
	// 						let previousViewCount = 0;

	// 						view.docs.forEach(v => {
	// 							const dateKey = moment(new Date(v.data().createdAt)).format('YYYY-MM-DD');
	// 							const exhibitionTitle = v.data().exhibitionTitle?.replace(/,/g, '');
	// 							const viewCount = v.data().viewCount;
	// 							const key = `${dateKey}_${exhibitionTitle}`;

	// 							// 첫날의 뷰는 항상 인정
	// 							if (!aggregatedData[key]) {
	// 								aggregatedData[key] = {
	// 									id: doc.id,
	// 									date: dateKey,
	// 									title: exhibitionTitle,
	// 									viewCount: viewCount,
	// 									link: `https://art.onthewall.io/${doc.id}`,
	// 								};
	// 							}

	// 							// if (viewCount > previousViewCount) {
	// 							// 	// 전날보다 뷰가 증가한 경우, 증가한 부분만큼 인정
	// 							// 	aggregatedData[key].viewCount += viewCount - previousViewCount;
	// 							// } else if (viewCount === previousViewCount) {
	// 							// 	// 전날과 동일한 경우, 뷰 제거
	// 							// 	// 이미 생성된 날짜에 대해서는 아무 것도 하지 않음
	// 							// } else if (viewCount < previousViewCount || viewCount === 0) {
	// 							// 	// 뷰가 줄어들거나 0인 경우, 전체 뷰를 인정 (초기화로 간주)
	// 							// 	aggregatedData[key].viewCount += viewCount;
	// 							// }

	// 							// previousViewCount = viewCount;
	// 						});

	// 						Object.values(aggregatedData).forEach(entry => {
	// 							csv += `${(entry as any).id}, ${(entry as any).date}, ${(entry as any).title}, ${
	// 								(entry as any).viewCount
	// 							}, ${(entry as any).link}\n`;
	// 						});
	// 					});
	// 				promises.push(p);
	// 			});

	// 			Promise.all(promises).then(() => {
	// 				console.log('csv', csv);
	// 				const blob = new Blob([csv], { type: 'text/csv' });
	// 				const url = window.URL.createObjectURL(blob);
	// 				const a = document.createElement('a');
	// 				a.setAttribute('hidden', '');
	// 				a.setAttribute('href', url);
	// 				a.setAttribute('download', 'exhibition.csv');
	// 				document.body.appendChild(a);
	// 				a.click();
	// 				document.body.removeChild(a);

	// 				console.log('csv2', csv2);
	// 				const blob2 = new Blob([csv2], { type: 'text/csv' });
	// 				const url2 = window.URL.createObjectURL(blob2);
	// 				const a2 = document.createElement('a');
	// 				a2.setAttribute('hidden', '');
	// 				a2.setAttribute('href', url2);
	// 				a2.setAttribute('download', 'exhibition2.csv');
	// 				document.body.appendChild(a2);
	// 				a2.click();
	// 				document.body.removeChild(a2);
	// 			});
	// 		});
	// }, []);

	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<SnackbarProvider dense maxSnack={3}>
						{/* <I18nextProvider i18n={i18n}> */}
						<Router history={history}>
							<AuthProvider>
								<GlobalStyles />
								<ScrollReset />
								<GoogleAnalytics />
								{renderRoutes(routes)}
							</AuthProvider>
						</Router>
						{/* </I18nextProvider> */}
					</SnackbarProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</ThemeProvider>
	);
};

export default App;
