import { Timestamp } from '@firebase/firestore-types';
import { enableAllPlugins } from 'immer';

enableAllPlugins();
import { produce } from 'immer';
import { ObjectType } from 'typescript';
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  // resetStatus,
  ActionStatus,
} from '../utils/reducerUtils';

export type Space = {
  id: string;
  title: string;
  matterportId: string;
  description: string;
  thumbnailImg?: {
    path: string;
    url: string;
  };
  category: string;
  owner: Array<string>;
  exhibitions: Array<string>;
  maxNumOfPainting: number;
  maxNumOfModel: number;
  numOfExhibitions: number;
  isPublic: boolean;
  isDeleted: boolean;
  updatedAt: number;
  createdAt: number;
};

interface SpaceState {
  spaceData: Space;
  allSpaces: Array<Space>;
  clientSpaces: Array<Space>;
  getSpaceStatus: ActionStatus;
  syncSpaceDataStatus: ActionStatus;
  postSpaceDataStatus: ActionStatus;
  updateSpaceDataStatus: ActionStatus;
  deleteSpaceDataStatus: ActionStatus;
  getAllSpacesStatus: ActionStatus;
  getClientSpacesStatus: ActionStatus;
}

// 글로벌 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: SpaceState = {
  spaceData: {
    id: '',
    title: '',
    matterportId: '',
    description: '',
    thumbnailImg: {
      path: '',
      url: '',
    },
    category: '',
    owner: [],
    exhibitions: [],
    maxNumOfPainting: 0,
    maxNumOfModel: 60,
    numOfExhibitions: 0,
    isPublic: false,
    isDeleted: false,
    updatedAt: null,
    createdAt: null,
  },
  allSpaces: [],
  clientSpaces: [],
  getSpaceStatus: createStatus(),
  syncSpaceDataStatus: createStatus(),
  postSpaceDataStatus: createStatus(),
  updateSpaceDataStatus: createStatus(),
  deleteSpaceDataStatus: createStatus(),
  getAllSpacesStatus: createStatus(),
  getClientSpacesStatus: createStatus(),
};

// 액션들의 집합.
export const actions = {
  GET_SPACE_REQUEST: 'GET_SPACE_REQUEST',
  GET_SPACE_SUCCESS: 'GET_SPACE_SUCCESS',
  GET_SPACE_FAILURE: 'GET_SPACE_FAILURE',
  POST_SPACE_REQUEST: 'POST_SPACE_REQUEST',
  POST_SPACE_SUCCESS: 'POST_SPACE_SUCCESS',
  POST_SPACE_FAILURE: 'POST_SPACE_FAILURE',
  UPDATE_SPACE_REQUEST: 'UPDATE_SPACE_REQUEST',
  UPDATE_SPACE_SUCCESS: 'UPDATE_SPACE_SUCCESS',
  UPDATE_SPACE_FAILURE: 'UPDATE_SPACE_FAILURE',
  DELETE_SPACE_REQUEST: 'DELETE_SPACE_REQUEST',
  DELETE_SPACE_SUCCESS: 'DELETE_SPACE_SUCCESS',
  DELETE_SPACE_FAILURE: 'DELETE_SPACE_FAILURE',
  GET_ALL_SPACES_REQUEST: 'GET_ALL_SPACES_REQUEST',
  GET_ALL_SPACES_SUCCESS: 'GET_ALL_SPACES_SUCCESS',
  GET_ALL_SPACES_FAILURE: 'GET_ALL_SPACES_FAILURE',
  GET_CLIENT_SPACES_REQUEST: 'GET_CLIENT_SPACES_REQUEST',
  GET_CLIENT_SPACES_SUCCESS: 'GET_CLIENT_SPACES_SUCCESS',
  GET_CLIENT_SPACES_FAILURE: 'GET_CLIENT_SPACES_FAILURE',
};

// 액션 크리에이터. dispatch 내부에서 사용.
export const getSpaceAction = (id: string) => ({
  type: actions.GET_SPACE_REQUEST,
  id,
});
export const postSpaceAction = (data: Space) => ({
  type: actions.POST_SPACE_REQUEST,
  data,
});
export const updateSpaceAction = (id: string, data: Space) => ({
  type: actions.UPDATE_SPACE_REQUEST,
  id,
  data,
});

export const getAllSpacesAction = (isPublic: boolean, id: string | null = null) => ({
  type: actions.GET_ALL_SPACES_REQUEST,
  isPublic,
  id,
});
export const getClientSpacesAction = (isPublic: boolean, id: string | null = null) => ({
  type: actions.GET_CLIENT_SPACES_REQUEST,
  isPublic,
  id,
});
export const deleteSpaceAction = (id: string) => ({
  type: actions.DELETE_SPACE_REQUEST,
  id,
});
// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_SPACE_REQUEST:
        draft.getSpaceStatus = createRequestStatus();
        break;
      case actions.GET_SPACE_SUCCESS:
        draft.getSpaceStatus = createSuccessStatus();
        draft.spaceData = action.data;
        break;
      case actions.GET_SPACE_FAILURE:
        draft.getSpaceStatus = createFailureStatus(action.error);
        break;
      case actions.POST_SPACE_REQUEST:
        draft.postSpaceDataStatus = createRequestStatus();
        break;
      case actions.POST_SPACE_SUCCESS:
        draft.postSpaceDataStatus = createSuccessStatus();
        break;
      case actions.POST_SPACE_FAILURE:
        draft.postSpaceDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_SPACE_REQUEST:
        draft.updateSpaceDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_SPACE_SUCCESS:
        draft.updateSpaceDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_SPACE_FAILURE:
        draft.updateSpaceDataStatus = createFailureStatus(action.error);
        break;
      case actions.DELETE_SPACE_REQUEST:
        draft.deleteSpaceDataStatus = createRequestStatus();
        break;
      case actions.DELETE_SPACE_SUCCESS:
        draft.deleteSpaceDataStatus = createSuccessStatus();
        break;
      case actions.DELETE_SPACE_FAILURE:
        draft.deleteSpaceDataStatus = createFailureStatus(action.error);
        break;
      case actions.GET_ALL_SPACES_REQUEST:
        draft.getAllSpacesStatus = createRequestStatus();
        break;
      case actions.GET_ALL_SPACES_SUCCESS:
        draft.getAllSpacesStatus = createSuccessStatus();
        draft.allSpaces = action.data;
        break;
      case actions.GET_ALL_SPACES_FAILURE:
        draft.getAllSpacesStatus = createFailureStatus(action.error);
        break;
      case actions.GET_CLIENT_SPACES_REQUEST:
        draft.getClientSpacesStatus = createRequestStatus();
        break;
      case actions.GET_CLIENT_SPACES_SUCCESS:
        draft.getClientSpacesStatus = createSuccessStatus();
        draft.clientSpaces = action.data;
        break;
      case actions.GET_CLIENT_SPACES_FAILURE:
        draft.getClientSpacesStatus = createFailureStatus(action.error);
        break;
      default:
        break;
    }
  });

export default reducer;
