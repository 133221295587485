import {CloudClient} from "../../types";

export const SET_CLIENT_DATA = "SET_CLIENT_DATA";

export const setClientData = data => {
  return {
    type: SET_CLIENT_DATA,
    payload: {
      data,
    },
  };
};

export const initialState: CloudClient = {
  id: "",
  logo: "",
};

const clientReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_CLIENT_DATA:
      return action.payload.data;

    default:
      return state;
  }
};

export default clientReducer;
