import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogTitle, makeStyles } from '@material-ui/core';
import {
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem as MuiMenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { LanguageRounded, NotificationsNoneRounded, PersonOutlineRounded } from '@material-ui/icons';

import * as Routes from '../../../routes';
import { SIDE_MENU } from '../../../types/sidemenu';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: ({ isExpanded }: { isExpanded: boolean }) => ({
    // width: "100%",
  }),
  menuItem: {
    display: 'flex',
  },
  drawerInnerBtn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
  },
  icon: {
    margin: 5,
    color: '#999999',
  },
  languageModal: {
    padding: 30,
  },
  dialogContent: {
    width: 200,
    height: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
type Props = {
  isExpanded: boolean;
};

function Menu({ isExpanded }: Props) {
  const { i18n } = useTranslation();
  const [openLanguage, setOpenLanguage] = useState(false);
  const SIDE_MENU_ITEMS: Array<SIDE_MENU> = [
    {
      type: 'divider',
    },
    {
      title: i18n.t('알림'),
      icon: NotificationsNoneRounded,
      type: 'route',
      value: Routes.notification,
    },
    {
      title: i18n.t('마이페이지'),
      icon: PersonOutlineRounded,
      type: 'route',
      value: Routes.mypage,
    },
  ];
  const classes = useStyles({ isExpanded });

  const lngs = [
    { value: 'ko-KR', title: '한국어' },
    { value: 'en-US', title: 'English' },
    // { value: "ja-JP", title: "日本語" },
    // { value: "zh-CN", title: "中文" },
    // { value: "my-MM", title: "မြန်မာဘာသာ" },
    // { value: "vi-VN", title: "Tiếng Việt" },
  ];
  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as string);
  };
  const handleChangeInModal = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as string);
    setOpenLanguage(false);
  };
  const onClickLanguage = () => {
    setOpenLanguage(true);
  };

  return (
    <div className={classes.root}>
      {isExpanded ? (
        <FormControl className={classes.drawerInnerBtn}>
          <InputLabel id='language-selector'>Language</InputLabel>
          <Select
            labelId='language-selector'
            value={i18n.language}
            label='Language'
            onChange={handleChange}
            size='small'
            fullWidth
            className={classes.drawerInnerBtn}
          >
            {lngs.map((language) => (
              <MuiMenuItem key={language.value} value={language.value}>{language.title}</MuiMenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <LanguageRounded onClick={onClickLanguage} className={classes.icon} />
      )}
      {/* {SIDE_MENU_ITEMS.map((menu) => (
        <MenuItem menu={menu} isExpanded={isExpanded} key={shortid.generate()} />
      ))} */}
      <Dialog open={openLanguage} onClose={() => setOpenLanguage(false)}>
        <DialogTitle>Select Language</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormControl className={classes.drawerInnerBtn}>
            <InputLabel id='language-selector'>Language</InputLabel>
            <Select
              labelId='language-selector'
              value={i18n.language}
              label='Language'
              onChange={handleChangeInModal}
              size='small'
              fullWidth
            >
              {lngs.map((language) => (
                <MuiMenuItem key={language.value} value={language.value}>{language.title}</MuiMenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Menu;
