import type { FC } from "react";
import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import {
  Box,
  Drawer,
  FormControl,
  Hidden,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { AccountCircle as AccountCircleIcon, Menu as MenuIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import * as Routes from "../../routes";
import Logo from "../../../public/static/logo-inline.svg";
import { useSelector } from "../../store";
import useAuth from "../../hooks/useAuth";
import useCloudMenu from "../../hooks/useCloudMenu";
import isMobile from "../../utils/isMobile";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ breakpoints }) => ({
  stickyContainer: {
    position: "fixed",
    width: "100vw",
    backgroundColor: "white",
    zIndex: 1000,
    // overflow: "hidden",
  },
  headerBox: {
    height: 56,
    width: "100%",
    padding: "0px 30px 0px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    lineHeight: 0,
    borderBottom: "1px solid #E9EEF4",
    [breakpoints.only("xs")]: {
      padding: "0px 20px",
    },
  },
  headerLogo: {
    cursor: "pointer",
  },
  headerNavi: {
    marginLeft: 30,
  },
  headerMenu: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  naviBtn: {
    padding: 13,
    verticalAlign: "middle",
    cursor: "pointer",
  },
  logoImage: {
    width: 200,
    "&.logo-placeholder": {
      borderRadius: "4px",
    },
  },
  drawerBtn: {
    cursor: "pointer",
  },
  drawerLogo: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    marginBottom: 20,
  },
  drawerInnerBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 20,
  },
  drawerItem: {
    padding: "13px 36px",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  bottomDrawerItem: {
    padding: "13px 36px",
    width: "100%",
    verticalAlign: "middle",
    cursor: "pointer",
  },
  languageSelector: {
    width: 120,
    marginRight: 20,
  },
  drawerNav: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const { id: clientId, logo, tier } = useSelector(state => state.client);
  const [isOpenDrawer, setOpenDrawer] = React.useState(false);
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const MENU = useCloudMenu();
  const clickMypage = () => {
    setOpen(false);
    history.push(`/${clientId}${Routes.cloudMypage}`);
  };
  const lngs = [
    { value: "ko-KR", title: "한국어" },
    { value: "en-US", title: "English" },
    // { value: "ja-JP", title: "日本語" },
    // { value: "zh-CN", title: "中文" },
    // { value: "my-MM", title: "မြန်မာဘာသာ" },
    // { value: "vi-VN", title: "Tiếng Việt" },
  ];
  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as string);
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => setOpenDrawer(false)}
      onKeyDown={() => setOpenDrawer(false)}
      className={classes.drawerNav}
    >
      <List>
        <div
          className={classes.drawerLogo}
          onClick={() => {
            history.push(`/${clientId}${Routes.cloudMain}`);
          }}
        >
          <img src={Logo} className={classes.logoImage} />
        </div>
        <div
          className={classes.drawerInnerBtn}
          onClick={() => {
            if (isMobile())
              return alert(
                "전시회 생성은 데스크탑에서 최적화 되어있습니다. 데스크탑에서 작업해주세요",
              );
            history.push(`/${clientId}${Routes.cloudRegisterExhibition}`);
          }}
        >
          <Button variant="contained" color="primary">
            {i18n.t("바로 전시회 생성하기")}
          </Button>
        </div>
        {MENU.map((menu, i) => (
          <React.Fragment key={`${menu.value}_${i}`}>
            {/*{menu.type === 'link' && <></>}*/}
            {menu.type === "route" && (
              <div className={classes.drawerItem} onClick={() => history.push(menu.value)}>
                <ListItemText primary={menu.title} />
              </div>
            )}
          </React.Fragment>
        ))}
        {user && (
          <>
            {/* <div
              className={classes.drawerItem}
              onClick={() => {
                history.push(`/${clientId}${Routes.cloudNotification}`);
              }}
            >
              <ListItemText primary={i18n.t("알림")} />
            </div> */}
            <div className={classes.drawerItem} onClick={clickMypage}>
              <ListItemText primary={i18n.t("마이페이지")} />
            </div>
          </>
        )}
      </List>
      <div className={classes.bottomDrawerItem}>
        <FormControl className={classes.drawerInnerBtn}>
          <InputLabel id="language-selector">Language</InputLabel>
          <Select
            labelId="language-selector"
            value={i18n.language}
            label="Language"
            onChange={handleChange}
            size="small"
            fullWidth
            className={classes.drawerInnerBtn}
          >
            {lngs.map(language => (
              <MenuItem key={language.value} value={language.value}>
                {language.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </Box>
  );
  return (
    <header>
      <div className={classes.stickyContainer}>
        <div className={classes.headerBox}>
          <div
            className={classes.headerLogo}
            onClick={() => {
              history.push(`/${clientId}`);
            }}
          >
            {logo ? (
              <img src={logo} className={classes.logoImage} />
            ) : (
              <img
                src="https://placehold.co/200x36?text=Header+Logo"
                className={`${classes.logoImage} logo-placeholder`}
              />
            )}
          </div>
          <Hidden mdDown>
            {user ? (
              <div className={classes.naviBtn} onClick={() => history.push(`/${clientId}/mypage`)}>
                <AccountCircleIcon />
              </div>
            ) : (
              <div className={classes.naviBtn} onClick={() => history.push(`/${clientId}/login`)}>
                <AccountCircleIcon />
              </div>
            )}
          </Hidden>
          <Hidden mdUp>
            <MenuIcon onClick={() => setOpenDrawer(true)} className={classes.drawerBtn} />
            <Drawer anchor={"right"} open={isOpenDrawer} onClose={() => setOpenDrawer(false)}>
              {list()}
            </Drawer>
          </Hidden>
        </div>
      </div>
    </header>
  );
};

export default Header;
