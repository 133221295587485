/*
 update the Open Graph tags
 클라이언트 측에서 JavaScript로 OG 태그를 변경하는 경우,
 웹 크롤러가 javascript를 실행하지 않는다면 원하는대로 OG 정보가 업데이트 되지 않을 수 있음.
 이 방법은 주로 사용자가 페이지 내에서 어떤 동작을 했을 때, 그 동작에 반응하여 OG 태그를 실시간으로 업데이트하고 싶을 때 사용.
 */
export function updateOgTags(title: string, description: string, imgUrl: string) {
    let metaTags = document.getElementsByTagName('meta');

    for (let i = 0; i < metaTags.length; i++) {
        if (metaTags[i].getAttribute('property') === 'og:title') {
            metaTags[i].setAttribute('content', title);
        }
        if (metaTags[i].getAttribute('property') === 'og:description') {
            metaTags[i].setAttribute('content', description);
        }
        if (metaTags[i].getAttribute('property') === 'og:image') {
            metaTags[i].setAttribute('content', imgUrl);
        }
    }
}

export function updateFavicon(faviconUrl: string) {
    let linkTags = document.getElementsByTagName('link');

    for (let i = 0; i < linkTags.length; i++) {
        if (linkTags[i].getAttribute('rel') === 'icon') {
            linkTags[i].setAttribute('href', faviconUrl);
        }
    }
}