import type { HTMLProps, ReactNode } from 'react';
import React, { forwardRef, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import track from '../utils/analytics';
import { useSelector } from '../store';
import { updateFavicon, updateOgTags } from '../utils/common';

interface PageProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...rest }, ref) => {
  const location = useLocation();
  const client = useSelector(state => state.client);

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (client?.id) {
      updateOgTags(client?.title || '', client?.title || '', client?.opengraph);
      updateFavicon(client?.faviconUrl || '');
    }
  }, [client.id]);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div ref={ref as any} {...rest}>
      <Helmet>
        <title>{client?.id ? `${client?.title || ''} ` : `걸어본 `}{`${title}`}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
