import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
interface CloudLayoutProps {
	children?: ReactNode;
}

type RouteParams = {
	id: string;
};

const useStyles = makeStyles(({ breakpoints }) => ({
	root: {
		// backgroundColor: theme.palette.background.default,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	mainSection: {
		marginTop: 80,
		minHeight: 'calc(100vh - 325px)',
		width: '100vw',
		display: 'flex',
		flexDirection: 'column',
	},
	main: {
		width: '100vw',
		maxWidth: '1200px',
		margin: '0 auto',
		padding: '0 15px',
	},
}));

const CloudLayout: FC<CloudLayoutProps> = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Header />
			<section className={classes.mainSection}>
				<div className={classes.main}>{children}</div>
			</section>
			<Footer />
		</div>
	);
};

CloudLayout.propTypes = {
	children: PropTypes.node,
};

export default CloudLayout;
