// 현재 사용하지 않는 사가. 관리자 업데이트 할 경우 사용할 예정
import { all, fork, put, call, takeEvery, take, cancel } from "redux-saga/effects";
import { actions } from "../reducers/collection";
import { firestore, rsfDB } from "../lib/firebase";

// call은 동기, fork는 비동기 요청
function* getObjectData(action) {
  const { objectType, collectionId, id } = action;
  try {
    const snapshot = yield call(
      rsfDB.getDocument,
      `Collection/${collectionId}/${objectType}/${id}`,
    );
    yield put({
      type: actions.GET_OBJECT_DATA_SUCCESS,
      data: { ...snapshot.data(), id: snapshot.id },
    });
  } catch (err) {
    yield put({
      type: actions.GET_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}

function* getAllObjectData(action) {
  const { objectType, collectionId } = action;
  try {
    const snapshot = yield call(
      rsfDB.getCollection,
      firestore()
        .collection("Collection")
        .doc(collectionId)
        .collection(objectType)
        .where("isDeleted", "!=", false),
    );
    const data = [];
    snapshot.forEach(doc => {
      data.push({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data()?.createdAt?.seconds * 1000,
        updatedAt: doc.data()?.updatedAt?.seconds * 1000,
      });
    });
    yield put({
      type: actions.GET_ALL_OBJECT_DATA_SUCCESS,
      data: data,
    });
  } catch (err) {
    yield put({
      type: actions.GET_ALL_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* getMyExhibitionsData(action) {
  const { collectionId } = action;
  try {
    const snapshot = yield firestore()
      .collection("Exhibition")
      .where("owner", "==", collectionId)
      .where("isDeleted", "==", false)
      .orderBy("updatedAt", "desc")
      .orderBy("createdAt", "desc")
      .get();
    const exhibitionData = [];
    snapshot.forEach(doc => {
      exhibitionData.push({
        ...doc.data(),
        id: doc.id,
        originalPosterImage: doc.data().originalPosterImage,
        compressedPosterImage: doc.data().compressedPosterImage,
        thumbnailPosterImage: doc.data().thumbnailPosterImage,
        createdAt: doc.data()?.createdAt?.seconds * 1000,
        updatedAt: doc.data()?.updatedAt?.seconds * 1000,
        paidAt: doc.data()?.paidAt?.seconds * 1000,
        expiredAt: doc.data()?.expiredAt?.seconds * 1000,
        publishedAt: doc.data()?.publishedAt?.seconds * 1000,
        like: doc.data()?.like ?? 0,
      });
    });
    yield put({
      type: actions.GET_MY_EXHIBITIONS_DATA_SUCCESS,
      data: exhibitionData,
    });
  } catch (err) {
    yield put({
      type: actions.GET_MY_EXHIBITIONS_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* syncMyExhibitionsData(action) {
  const { collectionId, cloud } = action;
  const task = yield fork(
    rsfDB.syncCollection,
    firestore()
      .collection("Exhibition")
      .where("owner", "==", collectionId)
      .where("isDeleted", "==", false)
      .orderBy("updatedAt", "desc")
      .orderBy("createdAt", "desc"),
    {
      successActionCreator: snapshot => {
        const data = [];
        snapshot.forEach(doc => {
          if (cloud === "cloud") {
            if (doc.data().cloudData) {
              data.push({
                ...doc.data(),
                id: doc.id,
                originalPosterImage: doc.data().originalPosterImage,
                compressedPosterImage: doc.data().compressedPosterImage,
                thumbnailPosterImage: doc.data().thumbnailPosterImage,
                createdAt: doc.data()?.createdAt?.seconds * 1000,
                updatedAt: doc.data()?.updatedAt?.seconds * 1000,
                paidAt: doc.data()?.paidAt?.seconds * 1000,
                expiredAt: doc.data()?.expiredAt?.seconds * 1000,
                publishedAt: doc.data()?.publishedAt?.seconds * 1000,
                like: doc.data()?.like ?? 0,
              });
            }
          } else {
            if (!doc.data().cloudData) {
              data.push({
                ...doc.data(),
                id: doc.id,
                originalPosterImage: doc.data().originalPosterImage,
                compressedPosterImage: doc.data().compressedPosterImage,
                thumbnailPosterImage: doc.data().thumbnailPosterImage,
                createdAt: doc.data()?.createdAt?.seconds * 1000,
                updatedAt: doc.data()?.updatedAt?.seconds * 1000,
                paidAt: doc.data()?.paidAt?.seconds * 1000,
                expiredAt: doc.data()?.expiredAt?.seconds * 1000,
                publishedAt: doc.data()?.publishedAt?.seconds * 1000,
                like: doc.data()?.like ?? 0,
              });
            }
          }
        });
        return {
          type: actions.SYNC_MY_EXHIBITIONS_DATA_SUCCESS,
          data,
        };
      },
      failureActionCreator: err => ({
        type: actions.SYNC_MY_EXHIBITIONS_DATA_FAILURE,
        err: err.message,
      }),
    },
  );
  yield take(actions.SYNC_ALL_OBJECT_DATA_DONE);
  yield cancel(task);
}
function* syncAllObjectData(action) {
  const { objectType, collectionId } = action;
  const task = yield fork(
    rsfDB.syncCollection,
    firestore()
      .collection("Collection")
      .doc(collectionId)
      .collection(objectType)
      .where("isDeleted", "==", false),
    {
      successActionCreator: snapshot => {
        const data = [];
        snapshot.forEach(doc => {
          data.push({
            ...doc.data(),
            id: doc.id,
            createdAt: doc.data()?.createdAt?.seconds * 1000,
            updatedAt: doc.data()?.updatedAt?.seconds * 1000,
          });
        });
        return {
          type: actions.SYNC_ALL_OBJECT_DATA_SUCCESS,
          data,
        };
      },
      failureActionCreator: err => ({
        type: actions.SYNC_ALL_OBJECT_DATA_FAILURE,
        err: err.message,
      }),
    },
  );
  yield take(actions.SYNC_ALL_OBJECT_DATA_DONE);
  yield cancel(task);
}
function* postObjectData(action) {
  const { objectType, collectionId, data } = action;
  try {
    yield call(rsfDB.addDocument, `Collection/${collectionId}/${objectType}`, {
      ...data,
      isDeleted: false,
      likeCount: 0,
      commentCount: 0,
      clickCount: 0,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.POST_OBJECT_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.POST_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* updateObjectData(action) {
  const { objectType, collectionId, id, data } = action;
  try {
    yield call(rsfDB.updateDocument, `Collection/${collectionId}/${objectType}/${id}`, {
      ...data,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    });
    yield put({
      type: actions.UPDATE_OBJECT_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* deleteObjectData(action) {
  const { objectType, collectionId, id } = action;
  try {
    yield call(rsfDB.updateDocument, `Collection/${collectionId}/${objectType}/${id}`, {
      isDeleted: true,
    });
    yield put({
      type: actions.DELETE_OBJECT_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_OBJECT_DATA_FAILURE,
      error: err.message,
    });
  }
}

function* watchGetObjectData() {
  yield takeEvery<string>(actions.GET_OBJECT_DATA_REQUEST, getObjectData);
}
function* watchDeleteObjectData() {
  yield takeEvery<string>(actions.DELETE_OBJECT_DATA_REQUEST, deleteObjectData);
}
function* watchGetAllObjectData() {
  yield takeEvery<string>(actions.GET_ALL_OBJECT_DATA_REQUEST, getAllObjectData);
}
function* watchSyncAllObjectData() {
  yield takeEvery<string>(actions.SYNC_ALL_OBJECT_DATA_REQUEST, syncAllObjectData);
}
function* watchPostObjectData() {
  yield takeEvery<string>(actions.POST_OBJECT_DATA_REQUEST, postObjectData);
}
function* watchUpdateObjectData() {
  yield takeEvery<string>(actions.UPDATE_OBJECT_DATA_REQUEST, updateObjectData);
}
function* watchGetMyExhibitionsData() {
  yield takeEvery<string>(actions.GET_MY_EXHIBITIONS_DATA_REQUEST, getMyExhibitionsData);
}
function* watchSyncMyExhibitionsData() {
  yield takeEvery<string>(actions.SYNC_MY_EXHIBITIONS_DATA_REQUEST, syncMyExhibitionsData);
}

export default function* globalSaga() {
  yield all([
    fork(watchGetObjectData),
    fork(watchPostObjectData),
    fork(watchUpdateObjectData),
    fork(watchGetAllObjectData),
    fork(watchSyncAllObjectData),
    fork(watchDeleteObjectData),
    fork(watchGetMyExhibitionsData),
    fork(watchSyncMyExhibitionsData),
  ]);
}
