import type { FC } from "react";
import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as Routes from "../../routes";
import { db, firestore } from "../../lib/firebase";
import { useSelector } from "../../store";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles(() => ({
  stickyContainer: {
    position: "fixed",
    width: "100%",
    backgroundColor: "white",
    zIndex: 1000,
    borderBottom: "1px solid #E9EEF4",
  },
  headerBox: {
    height: 80,
    width: "100%",
    maxWidth: 1200,
    padding: "0px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    lineHeight: 0,
    margin: "0 auto",
  },
  headerLogo: {
    cursor: "pointer",
  },
  headerNavi: {
    marginLeft: 30,
  },
  headerMenu: {
    marginLeft: "auto",
  },
  naviBtn: {
    padding: 13,
    verticalAlign: "middle",
    cursor: "pointer",
  },
  logoImage: {
    width: 200,
    "&.logo-placeholder": {
      borderRadius: "4px",
    },
  },
  createBtn: {},
}));

interface HeaderProps {}

const Header: FC<HeaderProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const { id: clientId, logo, tier } = useSelector(state => state.client);

  // 승인 요청
  function requestApprove() {
    if (user) {
      const userRef = db.collection("User").doc(user.id);
      userRef
        .get()
        .then(doc => {
          if (doc.exists) {
            const userData = doc.data();
            if (!userData?.cloudData?.[clientId]) {
              const isConfirmed = confirm(
                `전시를 생성하기 위해서는 관리자의 승인이 필요합니다. 승인을 요청하시겠습니까?`,
              );
              if (isConfirmed) {
                userRef
                  .update({
                    [`cloudData.${clientId}`]: {
                      approved: "wait",
                      isBlocked: false,
                      requestTime: firestore.Timestamp.now(),
                    },
                  })
                  .then(() => {
                    alert("신청되었습니다.");
                  })
                  .catch(error => {
                    console.error("Error updating document: ", error);
                  });
              }
            } else {
              history.push(`/${clientId}${Routes.cloudManageExhibition}`);
            }
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    }
  }

  const toExhibitionBuildPage = () => {
    if (!user) {
      history.push(`/${clientId}${Routes.cloudLogin}`);
      return;
    } else {
      requestApprove();
    }
  };

  return (
    <header>
      <div className={classes.stickyContainer}>
        <div className={classes.headerBox}>
          <div
            className={classes.headerLogo}
            onClick={() => {
              history.push(`/${clientId}`);
            }}
          >
            {logo ? (
              <img src={logo} className={classes.logoImage} />
            ) : (
              <img
                src="https://placehold.co/200x36?text=Header+Logo"
                className={`${classes.logoImage} logo-placeholder`}
              />
            )}
          </div>
          {tier !== "business" && (
            <div className={classes.createBtn}>
              <Button color="primary" onClick={toExhibitionBuildPage} variant="contained">
                가상전시 스튜디오
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
