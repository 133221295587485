import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import {
  createStatus,
  createRequestStatus,
  createSuccessStatus,
  createFailureStatus,
  resetStatus,
  ActionStatus,
} from "../utils/reducerUtils";
import { CollectionObject, ObjectsType } from "./collection";
export type Exhibition = {
  id: string;
  title: string;
  description?: string;
  owner: string; // 주인의 id
  space: any; // space 정보
  showcase: string; // Matterport id
  showcaseTitle?: string;
  isPublic?: boolean; // 안씀
  isPrivate?: boolean; // 전시 사용허가
  selected?: boolean;
  isPlatform?: boolean; // 플랫폼에 대한 표시
  objectLikeNum?: number;
  originalPosterImage?: {
    url: string;
    path: string;
  };
  compressedPosterImage?: {
    url: string;
    path: string;
  };
  thumbnailPosterImage?: {
    url: string;
    path: string;
  };
  views: {
    todayView: number;
    totalView: number;
  };
  editOptions: {
    isFixedAngle: boolean;
  };
  like: number;
  musicId?: string;
  musicTitle?: string;
  createdAt: number;
  updatedAt?: number;
  publishedAt?: number;
  cloudData: {
    publishStatus?: string;
  };
  paidAt: number;
  expiredAt: number;
  plan?: "free" | "basic" | "business";
  stagePlan?: "basic" | "business";
  hasMenuOptions?: {
    BGMButton: boolean;
    chatButton: boolean;
    infoButton: boolean;
  };
  hasLikeButton?: boolean;
  hasLinkButton?: boolean;
  hasObjectChat?: boolean;
};
interface ExhibitionState {
  exhibitionData: Exhibition;
  exhibitionObjects: Array<CollectionObject>;
  syncExhibitionDataStatus: ActionStatus;
  getExhibitionDataStatus: ActionStatus;
  updateExhibitionDataStatus: ActionStatus;
  postExhibitionDataStatus: ActionStatus;
  deleteExhibitionDataStatus: ActionStatus;
  postObjectToExhibitionDataSatatus: ActionStatus;
  syncExhibitionObjectDataStatus: ActionStatus;
  postExhibitionObjectDataStatus: ActionStatus;
  updateExhibitionObjectDataStatus: ActionStatus;
  deleteExhibitionObjectDataStatus: ActionStatus;
  publishExhibitionStatus: ActionStatus;
}
// Exhibition 데이터의 상태 기본값. 데이터를 갖고 오기 전
export const initialState: ExhibitionState = {
  exhibitionData: {
    id: "",
    title: "",
    description: "",
    owner: "",
    space: {},
    showcase: "",
    originalPosterImage: {
      url: "",
      path: "",
    },
    compressedPosterImage: {
      url: "",
      path: "",
    },
    thumbnailPosterImage: {
      url: "",
      path: "",
    },
    editOptions: {
      isFixedAngle: false,
    },
    musicId: "",
    musicTitle: "",
    isPrivate: false,
    selected: false,
    isPlatform: false,
    objectLikeNum: 0,
    createdAt: null,
    updatedAt: null,
    publishedAt: null,
    paidAt: null,
    expiredAt: null,
    views: {
      todayView: 0,
      totalView: 0,
    },
    cloudData: {
      publishStatus: null,
    },
    like: 0,
    hasMenuOptions: {
      BGMButton: true,
      chatButton: true,
      infoButton: true,
    },
    hasLikeButton: false,
    hasLinkButton: false,
    hasObjectChat: false,
  },
  exhibitionObjects: [],
  // status는 loading, done, error을 갖고있음.
  syncExhibitionDataStatus: createStatus(),
  getExhibitionDataStatus: createStatus(),
  updateExhibitionDataStatus: createStatus(),
  postExhibitionDataStatus: createStatus(),
  deleteExhibitionDataStatus: createStatus(),
  syncExhibitionObjectDataStatus: createStatus(),
  postExhibitionObjectDataStatus: createStatus(),
  updateExhibitionObjectDataStatus: createStatus(),
  deleteExhibitionObjectDataStatus: createStatus(),
  publishExhibitionStatus: createStatus(),
  postObjectToExhibitionDataSatatus: createStatus(),
};

// 액션들의 집합.
export const actions = {
  SYNC_EXHIBITION_DATA_REQUEST: "SYNC_EXHIBITION_DATA_REQUEST",
  SYNC_EXHIBITION_DATA_SUCCESS: "SYNC_EXHIBITION_DATA_SUCCESS",
  SYNC_EXHIBITION_DATA_FAILURE: "SYNC_EXHIBITION_DATA_FAILURE",
  SYNC_EXHIBITION_OBJECT_DATA_REQUEST: "SYNC_EXHIBITION_OBJECT_DATA_REQUEST",
  SYNC_EXHIBITION_OBJECT_DATA_SUCCESS: "SYNC_EXHIBITION_OBJECT_DATA_SUCCESS",
  SYNC_EXHIBITION_OBJECT_DATA_FAILURE: "SYNC_EXHIBITION_OBJECT_DATA_FAILURE",
  SYNC_EXHIBITION_OBJECT_DATA_DONE: "SYNC_EXHIBITION_OBJECT_DATA_DONE",
  POST_EXHIBITION_OBJECT_DATA_REQUEST: "POST_EXHIBITION_OBJECT_DATA_REQUEST",
  POST_EXHIBITION_OBJECT_DATA_SUCCESS: "POST_EXHIBITION_OBJECT_DATA_SUCCESS",
  POST_EXHIBITION_OBJECT_DATA_FAILURE: "POST_EXHIBITION_OBJECT_DATA_FAILURE",
  UPDATE_EXHIBITION_OBJECT_DATA_REQUEST: "UPDATE_EXHIBITION_OBJECT_DATA_REQUEST",
  UPDATE_EXHIBITION_OBJECT_DATA_SUCCESS: "UPDATE_EXHIBITION_OBJECT_DATA_SUCCESS",
  UPDATE_EXHIBITION_OBJECT_DATA_FAILURE: "UPDATE_EXHIBITION_OBJECT_DATA_FAILURE",
  DELETE_EXHIBITION_OBJECT_DATA_REQUEST: "DELETE_EXHIBITION_OBJECT_DATA_REQUEST",
  DELETE_EXHIBITION_OBJECT_DATA_SUCCESS: "DELETE_EXHIBITION_OBJECT_DATA_SUCCESS",
  DELETE_EXHIBITION_OBJECT_DATA_FAILURE: "DELETE_EXHIBITION_OBJECT_DATA_FAILURE",
  GET_EXHIBITION_DATA_REQUEST: "GET_EXHIBITION_DATA_REQUEST",
  GET_EXHIBITION_DATA_SUCCESS: "GET_EXHIBITION_DATA_SUCCESS",
  GET_EXHIBITION_DATA_FAILURE: "GET_EXHIBITION_DATA_FAILURE",
  UPDATE_EXHIBITION_DATA_REQUEST: "UPDATE_EXHIBITION_DATA_REQUEST",
  UPDATE_EXHIBITION_DATA_SUCCESS: "UPDATE_EXHIBITION_DATA_SUCCESS",
  UPDATE_EXHIBITION_DATA_FAILURE: "UPDATE_EXHIBITION_DATA_FAILURE",
  POST_EXHIBITION_DATA_REQUEST: "POST_EXHIBITION_DATA_REQUEST",
  POST_EXHIBITION_DATA_SUCCESS: "POST_EXHIBITION_DATA_SUCCESS",
  POST_EXHIBITION_DATA_FAILURE: "POST_EXHIBITION_DATA_FAILURE",
  RESET_POST_EXHIBITION_DATA: "RESET_POST_EXHIBITION_DATA",
  DELETE_EXHIBITION_DATA_REQUEST: "DELETE_EXHIBITION_DATA_REQUEST",
  DELETE_EXHIBITION_DATA_SUCCESS: "DELETE_EXHIBITION_DATA_SUCCESS",
  DELETE_EXHIBITION_DATA_FAILURE: "DELETE_EXHIBITION_DATA_FAILURE",
  POST_OBJECT_TO_EXHIBITION_DATA_REQUEST: "POST_OBJECT_TO_EXHIBITION_DATA_REQUEST",
  POST_OBJECT_TO_EXHIBITION_DATA_SUCCESS: "POST_OBJECT_TO_EXHIBITION_DATA_SUCCESS",
  POST_OBJECT_TO_EXHIBITION_DATA_FAILURE: "POST_OBJECT_TO_EXHIBITION_DATA_FAILURE",
  PUBLISH_EXHIBITION_REQUEST: "PUBLISH_EXHIBITION_REQUEST",
  PUBLISH_EXHIBITION_SUCCESS: "PUBLISH_EXHIBITION_SUCCESS",
  PUBLISH_EXHIBITION_FAILURE: "PUBLISH_EXHIBITION_FAILURE",
  RESET_PUBLISH_EXHIBITION_STATUS: "RESET_PUBLISH_EXHIBITION_STATUS",
  UPDATE_EXHIBITION_DATA_RESET: "UPDATE_EXHIBITION_DATA_RESET",
  SYNC_EXHIBITION_DATA_DONE: "SYNC_EXHIBITION_DATA_DONE",
  SET_EXHIBITION_DATA: "SET_EXHIBITION_DATA",
  RESET_EXHIBITION_DATA: "RESET_EXHIBITION_DATA",
};

// 액션 크리에이터. dispatch 내부에서 사용.
export const syncExhibitionDataAction = (id: string) => ({
  // 추후 실시간 유저 접속 수 혹은 view 수 동기화 등에 활용할 예정
  type: actions.SYNC_EXHIBITION_DATA_REQUEST,
  id,
});
// 해당 전시회에 속한 object의 데이터를 불러옴
export const syncExhibitionObjectDataAction = (objectType: ObjectsType, id: string) => ({
  type: actions.SYNC_EXHIBITION_OBJECT_DATA_REQUEST,
  objectType,
  id,
});
export const postExhibitionObjectDataAction = (objectType: ObjectsType, collectionId, id: string, data: any) => ({
  type: actions.POST_EXHIBITION_OBJECT_DATA_REQUEST,
  objectType,
  collectionId,
  id,
  data,
});
export const updateExhibitionObjectDataAction = (objectType: ObjectsType, exhibitionId: string, id: string, data: any) => ({
  type: actions.UPDATE_EXHIBITION_OBJECT_DATA_REQUEST,
  objectType,
  exhibitionId,
  id,
  data,
});
export const deleteExhibitionObjectDataAction = (objectType: ObjectsType, exhibitionId: string, id: string) => ({
  type: actions.DELETE_EXHIBITION_OBJECT_DATA_REQUEST,
  objectType,
  exhibitionId,
  id,
});
export const syncExhibitionObjectDataDoneAction = () => ({
  type: actions.SYNC_EXHIBITION_OBJECT_DATA_DONE,
});
export const getExhibitionDataAction = (id: string) => ({
  // 현재는 get 요청으로 데이터를 불러올 것.
  type: actions.GET_EXHIBITION_DATA_REQUEST,
  id,
});
export const updateExhibitionDataAction = (collectionId: string, id, data: any) => ({
  type: actions.UPDATE_EXHIBITION_DATA_REQUEST,
  collectionId,
  id,
  data,
});
export const postExhibitionDataAction = (id: string, data: any, isCloud: boolean = false) => ({
  type: actions.POST_EXHIBITION_DATA_REQUEST,
  id,
  data,
  isCloud,
});
// 고르고, isDeleted
export const deleteExhibitionDataAction = (collectionId: string, id: string) => ({
  type: actions.DELETE_EXHIBITION_DATA_REQUEST,
  collectionId,
  id,
});
export const setExhibitionDataAction = (data: any) => ({
  type: actions.SET_EXHIBITION_DATA,
  data,
});
export const resetExhibitionDataAction = () => ({
  type: actions.RESET_EXHIBITION_DATA,
});
export const postObjectToExhibitionDataAction = (objectType: ObjectsType, exhibitionId: string, objectId: string, data: any) => ({
  type: actions.POST_OBJECT_TO_EXHIBITION_DATA_REQUEST,
  objectType,
  exhibitionId,
  objectId,
  data,
});
export const syncExhibitionDataDoneAction = () => ({
  type: actions.SYNC_EXHIBITION_DATA_DONE,
});
export const resetPostExhibitionData = () => ({
  type: actions.RESET_POST_EXHIBITION_DATA,
});
export const publishExhibitionAction = (id) => ({
  type: actions.PUBLISH_EXHIBITION_REQUEST,
  exhibitionId: id,
});
export const resetPublishExhibitionAction = () => ({
  type: actions.RESET_PUBLISH_EXHIBITION_STATUS,
});

// 각 액션에 따라 어떻게 데이터를 변경할 것인가를 정한다.
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.SYNC_EXHIBITION_DATA_REQUEST:
        draft.syncExhibitionDataStatus = createRequestStatus();
        break;
      case actions.SYNC_EXHIBITION_DATA_SUCCESS:
        draft.syncExhibitionDataStatus = createSuccessStatus();
        draft.exhibitionData.id = action.id;
        draft.exhibitionData.title = action.data.title;
        draft.exhibitionData.description = action.data.description;
        draft.exhibitionData.owner = action.data.owner;
        draft.exhibitionData.showcase = action.data.showcase;
        draft.exhibitionData.originalPosterImage = action.data.originalPosterImage ?? { url: "", path: "" };
        draft.exhibitionData.compressedPosterImage = action.data.compressedPosterImage ?? { url: "", path: "" };
        draft.exhibitionData.thumbnailPosterImage = action.data.thumbnailPosterImage ?? { url: "", path: "" };
        draft.exhibitionData.objectLikeNum = action.data.objectLikeNum ?? 0;
        draft.exhibitionData.views = action.data.views;
        draft.exhibitionData.like = action.data.like ?? 0;
        draft.exhibitionData.musicId = action.data.musicId;
        draft.exhibitionData.musicTitle = action.data.musicTitle;
        draft.exhibitionData.hasMenuOptions = action.data.hasMenuOptions ?? {
          BGMButton: true,
          chatButton: true,
          infoButton: true,
        };
        draft.exhibitionData.hasLikeButton = action.data.hasLikeButton ?? false;
        draft.exhibitionData.hasLinkButton = action.data.hasLinkButton ?? false;
        draft.exhibitionData.hasObjectChat = action.data.hasObjectChat ?? false;
        draft.exhibitionData.updatedAt = action.data.updatedAt?.seconds * 1000 ?? null;
        draft.exhibitionData.publishedAt = action.data.publishedAt?.seconds * 1000 ?? null;
        draft.exhibitionData.cloudData.publishStatus = action.data.cloudData?.publishStatus ?? null;
        draft.exhibitionData.createdAt = action.data.createdAt?.seconds * 1000 ?? null;
        draft.exhibitionData.paidAt = action.data?.paidAt?.seconds * 1000 ?? null;
        draft.exhibitionData.expiredAt = action.data?.expiredAt?.seconds * 1000 ?? null;
        draft.exhibitionData.plan = action.data.plan ?? "basic";
        draft.exhibitionData.stagePlan = action.data.stagePlan ?? "basic";
        draft.exhibitionData.showcaseTitle = action.data.showcaseTitle;
        draft.exhibitionData.isPrivate = action.data.isPrivate ?? false;
        draft.exhibitionData.selected = action.data.selected ?? false;
        draft.exhibitionData.isPlatform = action.data.isPlatform ?? false;
        draft.exhibitionData.objectLikeNum = action.data.objectLikeNum ?? 0;
        draft.exhibitionData.editOptions.isFixedAngle = action.data.editOptions?.isFixedAngle ?? false;
        draft.exhibitionData.space = action.data.space;
        break;
      case actions.SYNC_EXHIBITION_DATA_FAILURE:
        draft.syncExhibitionDataStatus = createFailureStatus(action.error);
        break;
      case actions.GET_EXHIBITION_DATA_REQUEST:
        draft.getExhibitionDataStatus = createRequestStatus();
        break;
      case actions.GET_EXHIBITION_DATA_SUCCESS:
        draft.getExhibitionDataStatus = createSuccessStatus();
        draft.exhibitionData.id = action.id;
        draft.exhibitionData.title = action.data.title;
        draft.exhibitionData.description = action.data.description;
        draft.exhibitionData.owner = action.data.owner;
        draft.exhibitionData.showcase = action.data.showcase;
        draft.exhibitionData.originalPosterImage = action.data.originalPosterImage ?? { url: "", path: "" };
        draft.exhibitionData.compressedPosterImage = action.data.compressedPosterImage ?? { url: "", path: "" };
        draft.exhibitionData.thumbnailPosterImage = action.data.thumbnailPosterImage ?? { url: "", path: "" };
        draft.exhibitionData.views = action.data.views;
        draft.exhibitionData.like = action.data.like ?? 0;
        draft.exhibitionData.musicId = action.data.musicId;
        draft.exhibitionData.musicTitle = action.data.musicTitle;
        draft.exhibitionData.hasMenuOptions = action.data.hasMenuOptions ?? {
          BGMButton: true,
          chatButton: true,
          infoButton: true,
        };
        draft.exhibitionData.updatedAt = action.data.updatedAt?.seconds * 1000 ?? null;
        draft.exhibitionData.publishedAt = action.data.publishedAt?.seconds * 1000 ?? null;
        draft.exhibitionData.cloudData.publishStatus = action.data.cloudData?.publishStatus ?? null;
        draft.exhibitionData.paidAt = action.data?.paidAt?.seconds * 1000 ?? null;
        draft.exhibitionData.expiredAt = action.data?.expiredAt?.seconds * 1000 ?? null;
        draft.exhibitionData.hasLikeButton = action.data.hasLikeButton ?? false;
        draft.exhibitionData.hasLinkButton = action.data.hasLinkButton ?? false;
        draft.exhibitionData.hasObjectChat = action.data.hasObjectChat ?? false;
        draft.exhibitionData.isPrivate = action.data.isPrivate ?? false;
        draft.exhibitionData.selected = action.data.selected ?? false;
        draft.exhibitionData.isPlatform = action.data.isPlatform ?? false;
        draft.exhibitionData.objectLikeNum = action.data.objectLikeNum ?? 0;
        draft.exhibitionData.editOptions.isFixedAngle = action.data.editOptions?.isFixedAngle ?? false;
        draft.exhibitionData.space = action.data.space;
        break;
      case actions.GET_EXHIBITION_DATA_FAILURE:
        draft.getExhibitionDataStatus = createFailureStatus(action.error);
        break;
      case actions.POST_EXHIBITION_DATA_REQUEST:
        draft.postExhibitionDataStatus = createRequestStatus();
        break;
      case actions.POST_EXHIBITION_DATA_SUCCESS:
        draft.postExhibitionDataStatus = createSuccessStatus();
        draft.exhibitionData.id = action.id;
        break;
      case actions.POST_EXHIBITION_DATA_FAILURE:
        draft.postExhibitionDataStatus = createFailureStatus(action.error);
        break;
      case actions.RESET_POST_EXHIBITION_DATA:
        draft.postExhibitionDataStatus = resetStatus();

        break;
      case actions.DELETE_EXHIBITION_DATA_REQUEST:
        draft.deleteExhibitionDataStatus = createRequestStatus();
        break;
      case actions.DELETE_EXHIBITION_DATA_SUCCESS:
        draft.deleteExhibitionDataStatus = createSuccessStatus();
        break;
      case actions.DELETE_EXHIBITION_DATA_FAILURE:
        draft.deleteExhibitionDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_EXHIBITION_DATA_REQUEST:
        draft.updateExhibitionDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_EXHIBITION_DATA_SUCCESS:
        draft.updateExhibitionDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_EXHIBITION_DATA_FAILURE:
        draft.updateExhibitionDataStatus = createFailureStatus(action.error);
        break;
      case actions.POST_OBJECT_TO_EXHIBITION_DATA_REQUEST:
        draft.postObjectToExhibitionDataSatatus = createRequestStatus();
        break;
      case actions.POST_OBJECT_TO_EXHIBITION_DATA_SUCCESS:
        draft.postObjectToExhibitionDataSatatus = createSuccessStatus();
        break;
      case actions.POST_OBJECT_TO_EXHIBITION_DATA_FAILURE:
        draft.postObjectToExhibitionDataSatatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_EXHIBITION_DATA_RESET:
        draft.updateExhibitionDataStatus = resetStatus();
        break;
      case actions.SYNC_EXHIBITION_OBJECT_DATA_REQUEST:
        draft.syncExhibitionObjectDataStatus = createRequestStatus();
        break;
      case actions.SYNC_EXHIBITION_OBJECT_DATA_SUCCESS:
        draft.syncExhibitionObjectDataStatus = createSuccessStatus();
        draft.exhibitionObjects = action.data;
        break;
      case actions.SYNC_EXHIBITION_OBJECT_DATA_FAILURE:
        draft.syncExhibitionObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.POST_EXHIBITION_OBJECT_DATA_REQUEST:
        draft.postExhibitionObjectDataStatus = createRequestStatus();
        break;
      case actions.POST_EXHIBITION_OBJECT_DATA_SUCCESS:
        draft.postExhibitionObjectDataStatus = createSuccessStatus();
        break;
      case actions.POST_EXHIBITION_OBJECT_DATA_FAILURE:
        draft.postExhibitionObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_EXHIBITION_OBJECT_DATA_REQUEST:
        draft.updateExhibitionObjectDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_EXHIBITION_OBJECT_DATA_SUCCESS:
        draft.updateExhibitionObjectDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_EXHIBITION_OBJECT_DATA_FAILURE:
        draft.updateExhibitionObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.DELETE_EXHIBITION_OBJECT_DATA_REQUEST:
        draft.deleteExhibitionObjectDataStatus = createRequestStatus();
        break;
      case actions.DELETE_EXHIBITION_OBJECT_DATA_SUCCESS:
        draft.deleteExhibitionObjectDataStatus = createSuccessStatus();
        break;
      case actions.DELETE_EXHIBITION_OBJECT_DATA_FAILURE:
        draft.deleteExhibitionObjectDataStatus = createFailureStatus(action.error);
        break;
      case actions.PUBLISH_EXHIBITION_REQUEST:
        draft.publishExhibitionStatus = createRequestStatus();
        break;
      case actions.PUBLISH_EXHIBITION_SUCCESS:
        draft.publishExhibitionStatus = createSuccessStatus();
        break;
      case actions.PUBLISH_EXHIBITION_FAILURE:
        draft.publishExhibitionStatus = createFailureStatus(action.error);
        break;
      case actions.RESET_PUBLISH_EXHIBITION_STATUS:
        draft.publishExhibitionStatus = resetStatus();
        break;
      case actions.SET_EXHIBITION_DATA:
        draft.exhibitionData.isPublic = action.data.isPublic ?? draft.exhibitionData.isPublic ?? true;
        draft.exhibitionData.space = action.data.space ? action.data.space : draft.exhibitionData.space ?? {};
        draft.exhibitionData.showcase = action.data.showcase ? action.data.showcase : draft.exhibitionData.showcase ?? "";
        draft.exhibitionData.showcaseTitle = action.data.showcaseTitle
          ? action.data.showcaseTitle
          : draft.exhibitionData.showcaseTitle ?? "";
        draft.exhibitionData.title = action.data.title ?? draft.exhibitionData.title ?? "";
        draft.exhibitionData.description = action.data.description ?? draft.exhibitionData.description ?? "";
        draft.exhibitionData.isPrivate = action.data.isPrivate ?? draft.exhibitionData.isPrivate ?? false;
        draft.exhibitionData.selected = action.data.selected ?? draft.exhibitionData.selected ?? false;
        draft.exhibitionData.originalPosterImage.url =
          action.data.originalPosterImage?.url ?? draft.exhibitionData.originalPosterImage?.url ?? "";
        draft.exhibitionData.compressedPosterImage.url =
          action.data.compressedPosterImage?.url ?? draft.exhibitionData.compressedPosterImage?.url ?? "";
        draft.exhibitionData.thumbnailPosterImage.url =
          action.data.thumbnailPosterImage?.url ?? draft.exhibitionData.thumbnailPosterImage?.url ?? "";
        draft.exhibitionData.originalPosterImage.path =
          action.data.originalPosterImage?.path ?? draft.exhibitionData.originalPosterImage?.path ?? "";
        draft.exhibitionData.compressedPosterImage.path =
          action.data.compressedPosterImage?.path ?? draft.exhibitionData.compressedPosterImage?.path ?? "";
        draft.exhibitionData.thumbnailPosterImage.path =
          action.data.thumbnailPosterImage?.path ?? draft.exhibitionData.thumbnailPosterImage?.path ?? "";
        break;
      case actions.RESET_EXHIBITION_DATA:
        draft.exhibitionData = {
          id: "",
          title: "",
          description: "",
          owner: "",
          isPrivate: false,
          selected: false,
          space: {},
          showcase: "",
          originalPosterImage: {
            url: "",
            path: "",
          },
          compressedPosterImage: {
            url: "",
            path: "",
          },
          thumbnailPosterImage: {
            url: "",
            path: "",
          },
          musicId: "",
          musicTitle: "",
          createdAt: null,
          updatedAt: null,
          publishedAt: null,
          cloudData: {
            publishStatus: null,
          },
          paidAt: null,
          expiredAt: null,
          views: {
            todayView: 0,
            totalView: 0,
          },
          like: 0,
          hasMenuOptions: {
            BGMButton: true,
            chatButton: true,
            infoButton: true,
          },
          hasLikeButton: false,
          hasLinkButton: false,
          hasObjectChat: false,
          editOptions: {
            isFixedAngle: false,
          },
        };
        break;
      default:
        break;
    }
  });

export default reducer;
